
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index2FHUXJdz2HGtQrhykEoUrkbX3m5DNkSqP5gUZfH7sWIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginyYnugx9LnY_45ThhojTeoD1r9lnP0TDG4TpEAqQkwW_xwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyhp6HIJReGOK0X58_LIngzRwxvrfEp2x7r5rO3zZzNEcMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemap4apOXfeE1s0n6WG1G7eccoD8XVUWu_0y2P_45OUgqKrsEMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usfwHVD5hJeG_4518L88N9MVlHu40zQ1xiCloMJvbW6H3xwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerSkx0eXMBB6HeDb7etMFB74q8hSl0fk0hKx5OhJROiI4Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaints6WLZUTY0n7drBUisKE14AhvDlWyQyotYJuthxg0IvcwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usH29W6AuKKoCVB1AaIokdmnXbM_Tbzv7S1GJl3CbWVKYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexM59BCH02sN6TYOniKYslX5CIzmkgtSWCL2rjCKXe_45wgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as index04PCHrAmrbxCibs6GJWzKm6RZdldPTjA3ReBL7N_45t1EMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93DjC3PkyLBCZypczhyQb_jI7myi7G8oZHpI1jKRFRvNIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsa2gC93IJOTEwBeKnro5ZpnKWCygYhaQRBsLHDq_45MWWsMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45use1rOVM1c4ki_45TPQqN4VDjlGglXC0A_45j0RrIIvydlskhsMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexBJcbcWo0s4zSERQTRa4hdwTKl3_0yzhxWy2p5EjmTlkMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyk8mwb5HWm37ae_45CwsuoY4rLh2rJS_H1FpUyxhe8L5rgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordVOR8D6hUPDBADudkHEbiyyGjbjRG5bVo10KqXcLNsaYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexcrYnakIrVKe5KdP4evVPVaGuW_ITubJxi4Qr0PMJkYEMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexEUJWD_NF9A2VljgtKlwTaNVt5JCGmz6i8SLCGpTRjKUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordB1Hp_45ikkS92u5UwzCYfURX0uqAjzu285Y52a9sZr4q0Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errorm5_NrES9__uep0iEhFygVcCxjk6DLt9A21LS8vHK4GAMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexV_bQUT_0co0D1OOKkNrLdN5pvZxmWF6bRtgi4ieXpAEMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93SPrbRQY_DWW1KOHRJ9Gljn6A0Nf6EQ4xwAcJiekJOrcMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guides_45sWBldNHd1jaZY1dOSX_45yXk4yNhy6Ln_Mt_45aUjW0A3kMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeOwXg2k3_45nQPTKh0lZsFDV4UD_456sVCQw_WuuWcJYO0igMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexNR0HeLcvPWQLG_AVk1QmS0mojCQwRcBkin4UzN3vXywMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordwI2eJ5p4O7kLGnvtJWLMimzn_mIhaDi1VL62p75XJF8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backaLb36rzl73yLBdjqsVwRKu78n6sAXkzGENeKNKgDBMIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergeLWmoUuna7icHY3CKQFsafI6C5pchvxH_45Xazwyo3lNcEMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as index5Z9NcN2J_WDSEVh7eZ1HFORLlQCR_45LVMlmKuewsPJ10Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_937SgBY5TXZRVj0KzsuiuibkSrxMKcQWxKM3s0fPQQaSgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youa68dU1bblqoHeJdWxf8HxARBranCuOf4dwyYx0RbFqcMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youEgjdh6lNNCacRZ2czGwVCYe6RCaWAsKlwq_4qCclj9YMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexHm7N7lWUxlI6_45fzud_9Y8xuU5yZLNJiKEr_jww2JOqQMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as index6zCnpdgaai8jf2bFdftHNeNlW0XcFBzljMNpFAXjDGUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_931dgCDOQIkKbfSFZoU8EC6kKyLMbI4aRIMt1Xd5EgS54Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexN1AgT5AJa7iJrSyH7oebZfF6esxk3y9ikBaf5bIGLUYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexXecba3REDG0hqzToU5KXk9QCqtxCGCBRjXcBIQ4TpEoMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93DemX3URfwngFAhFK4kfmI_8p4kk5qjhTqFxBtQsw27AMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackVNqFLNDOamZ4bRp4RWMjguNDqq0IkNP9J_45qQzdfB0zoMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claim0f_459r1Q5ptF2_wz7ArmQMR_Hu_ZZ4_45TurRchBlPt7HQMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index0Ihw57FlY1NEuOrdzz5smrKPN9zmLMHJdHUCn_ld6vsMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youkSR7ijhek5WVs2WexeegamsHx4W75dxJsr0wRpOL1xUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as error4RY1NENJL6AcYoLmeyXNNci9bTMUBK2ZuFXWeNzpIpwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexnk6Hvxwad2RJlGfR02zsSxJNZWNME0azcpDZgRZeMt8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as index1Xej1kaQxUXvMNohiFTf82EXPMldgWPLcLDz9n0eDPkMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as error0Y4jIu9Mcfph9EtQni7pKwbiFWo8cNqbdk30v5Sm0pUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93kc5_6VNt_igKLUk94c6gEV3InzDoMnZVn7z0WJvCwVgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsYnjfs2R0S4JHC5ctCjLDwqdsW5qzbzGRcXlhmSvYjgIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesy_XYqvQV3A_wopvk2tGGXE4NZKIgUQSLn6aYUtiB4JQMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93X5upMNi0wnmhUROM84ItCw73Anfc4pRra8djvEgr_mcMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexl3_whiCUG_450dGvV_K9dTbwISV6FnrPtuSRMxJ4B5j4wMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsKLycZV9A35GGJSrGU2eqgLCqs485rQSIM4HJdNf2iVUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexf143BKTJNEdpKLhVuwMdhJehW_45704O0EReHrItQM2DMMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatoWJh3Q99IPgQHNk4x8xmf_45Qh_A3C3swswzeVHHPnTaEMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleJvzubfC8TBaF6Rxk2XkfZOuPWhGlADxeP89I14q9UG4Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45you0Js00X0w_j49ta4ek3INmfq3LLfUbz_45vVO1tsUt66vgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensivei1KKEY9rC0WyYNiEQ6a2E3uQ9k71WzLVkjzcNcqxpfkMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_939kXwlzjoNqRoxVmo62rxPV9ox5f5tReR6GIIz45KTK4Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addUByIir0JQ15nkDFjDd_Ttc82rpnGnUmPReyRipOaCh0Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexPbk8Mbj46Lsv5sRLiTwgcYYyFlgdwlBElYa98u38iaUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93PFFw_TYtgJSTVhWtHvuDa2IMZMEO0wTDrQUy41pzQvUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackXiMQzER0Wf6s9YpIbKhrhoaPuuRO6NPGtDVhUEhTRCcMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93X6_45utmNNiEzhR8_Yt_5bPaFTO4GXMTczojgpgW4NlHIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youcUY50Lc0Ri85RqCIHyJ8_450_Y98s415MUtW6uLQIbLCMMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bBWuxZX52We2fN2DJxvKuBoBBwDeZI058nPI9vsdWUCMMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexvMZ343g1tnYMJU9LqVS9VE9vAXmL6tgz12wxJGcItCAMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93VkQS5_45hXKtaN2RDz0YtEsBDvhsJaYJItmBGMYiJiT_IMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93sHFybiomYeure5NwON4bUH3YZsY7WulFk4Q1U7VZdEoMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as index3MwMyKiLi_45YKEpKcDxzehqRhIiAKFhTU15Y4aVQfiukMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addJHb9BjcC84PylWK1ZdWPZlGgAabCwMYuh3otkaLIseIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93SrSpeBQ7s59_45gzokOHHQZC0FmOxwByAxBKRMai_tmFgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsP4Itj1xulamUPcoIcpAo1_3e4l6U03OliKIL3dMN7F4Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackKDTRo57fvo3wytu1xk2cKOss5BmQccDEToBuJ0jUKYsMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eic6huorb_45UYLzFrt8XG_y3FDODNz1PZgGFZAu9vrIFF_45UMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45you5qsO1MA_gI_45GSgEj9HfmifVEp8WXv9UypMrBcnZyrmwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93_45wyWH4fiecFk4wRuebmwYtSOxpBFuBJjzy1DTnb7wUgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93TMg2UYnpKusJOv5HLq6KM9QQ2eF_q1_TN1exsgc_CJkMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonlytoEnDdBp8JpDkCGVVH3neyWLB8McGBnAqX3n8n64EMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1RQvUxii_H_45Mxjn2t_N4o4IC6yLHljUNseLKGWk6ETAYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as details6ga7V94UD66cmtZRvLWbJqJro01zYqfIqUxQ_45LFo9f8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsxgv4GSiCYile0KUAAbc75Obgt6BcZCuM64tf1KyIg9kMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as index9Ww_qhMO1l7YBSv0CHmTEiAzNL4XumiXNr7DObfudHwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupchAftw_NYDo_45J5tu13nylP3hvXqrI6b_45vaHZvtmH1SgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youriZEp5MVm7uWYmHCOXwmjMbIau_PdFKHclXIbb1vnjsMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93OFjNmKxtsnVHvtTmAm9TmvY2GCRu_zmuuDyXjvUkZ0EMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_935wMWCXP2Y84y23K5Q7hPssP5s0J8OuwFw3f0xcyahXgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0LMCRH_wKiy5AgPy3vsUse5suCwQcjzrgRyzg1i65A68Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersqtJwLGN6Ythw5Yp4V7vn01iprFSlLsEOqnTyLNqsHdYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45yougt3O8SM6yzjX1awMU_mmIx1Yg4Jy7CnMs7_bem81SAEMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsGDYYNwM__6L9Mmjh_45LL9cjgdbmmy3w4_yhkrJ_45XROGYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as details1HhmMZzks5whihZq_45PPIljrd9uMF0uOiwbMu12u9gnoMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_936BumUREypgHdXoPUgnaT0omNryxIno1LEn8WevP2MCQMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate3EwQXgWeGSi4sa9RTVA3jo_HjPuhvUDgBFPJgFb4eWoMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_933LWgjw086PkF34XdFSGSFmlCWDxrbuyE7aB5hSWBuBMMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsPx49_458iHWYqqz0PoGuJxvHi0W5BcNqYQWTsfmSe9YWYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0N6tDuNvYOwI_45aaWJeeUjWyaVBis9q5hG_PoRnk7qQzIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexBTnCUYtbrEK_Knuwcd4sg1dJrhGja_lm6rlQ8_45Pz4RAMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexNhibTtv9yF71ypNuTUTImbwybT2ovNQlIelKCJi6zOwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as details0TsErlx0_45oBK5zabrYc7CHbuRFxZtpXv5h8xVGiwgaoMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsT_45ut4AC_45ZhS8MwtmJLEXfCJEPnaZ7aJKtb16QnRWQAoMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_938oGGU86goRtoXCDpCfEPzbxRePGvJxpM0zbQRcY3ms8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as indexCRw_45PZdIyqjwaUL8_G4OZxTJQYDrt8GpaFRhRv2uT2wMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsyMmETKhWnQKbcJEBuPF4yoQCyfYQb_45oVB2WI_45GlGjqgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexziVrioN6FNRjE7Ga_456BZIx3DDSWwI5yfihfX0rybBrUMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successLxVEMR9Jxe_8aCZ1PUzFqDLiklxcGYWM69uaTgelG9QMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerP_2zSeOZXyhPhv043EKp6NZRX1JGFN7gHGljnJUBBqgMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsC4sID4HUb8QsZwfPU92zdgjjNrxVD8c7J31xkedYEbEMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourz0N8rCOny52D9aICZoI9f3raxoHezLPkAKw7qVWt72UMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93_beMCwuRiNYjYDtffWK0ViZLVmOsEJAEj_V4fCTdjj4Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexpYVjszYyXQkQnXfMd4skEkhMLr0eVqkM1Hytzrvqot8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93fOen6NCv79IYcyDKZ3N2GPeBa_45U4Z0NgRrzGWbY5l_45IMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexi86wbHIILfCfOaRgbc0SFd5gyB92rTQDOZkI35ySjVAMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93zIc_g2nKTBia1gxnAm6mLYOE_shwiqTSvP1uBUSiOB0Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45youSsrGJlaRBuaVM2ETr0aEf5LDn5FahrI0q9WLCWKuX94Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexOO6hQEhHDW_45kpfVNXVLQyRxtElSLPCIsw4atwP9vgloMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexjpIpxn_u2L8d5wWtNiTCZVSfBKXCIvDsDsTevz7gIE8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsQ__WwISernqq8z1S03asc8S7Jk3DUn6RU_Hm0QR07k8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredqbZ7jqEbbkEdYsfpsIA4z_i21CQgC3L6VTwoNJDHc0kMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexv_45lx7449LreM4u2JO8GTxfJ5PNRTsvpw6B59P03JRc4Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youVNg6QJ6eCeqv428FXuai_lqaC4axjTpstnCe5mZDLwkMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsmkmb6o750XPJIcO80cE3lJ0lke_gKKkUFoFGt6yCBl4Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentvmimHzHcF1QVjCCocFpH_uzmnYTf4ArWlQqq3jwbDx8Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmationGoSAx5It2ZSddNlLKKHf2SF4Bf2yd50M3m829uIFZL0Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionINPkONJs0kdZbExf5gDV1fPgmZxKDVjSYqPPVsJltYYMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultseLbUvpb8v9OpG4OOZOoO7ptodCg6DOumyxl104TEtTIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationn_459LkWrqx4Ido8K_wkVXIViPpBsiuWmBapOjdbKYz_0Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93xfrNSDT9oxd3UZwsnXeSNAv7ZKpFuClilsSUtoff8DIMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93lXN9OvVmGh5ZXu66lq85IKw1XcHmfP_VXLj2aqyLUa0Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93txxL8qBJ8u6Nx25vWtZllWOLm2ZlBDCknXTFMurs0XQMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93u3YXekKH8ViBpiQObxkYlUvtWlNKhai4YbbXeNMxdaAMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as index89EJPLwIha5yibQIk9OuEqlvfUk3Xt_3gEooouNNgakMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailssIQxKn0ew7M3HvrhEvRCLYEPsnQh94fKEbISe8tTKkwMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93VWFF_45BpwLcLJRnRloKoGCEHyZR7DFTmq2rs49pEij3UMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_937degR4dorbZnwNFrFBuUiVP1DlAdpR4vDZwKngerE5sMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexcq7NJmSWIkF4tZ9WrgqZ9DFSq1KIT61FPCKD90brx08Meta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as index_45Cripx5i_45mEzv_o0s7XQFt0AYcg_aOVvru6E3mIIDLcMeta } from "/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginyYnugx9LnY_45ThhojTeoD1r9lnP0TDG4TpEAqQkwW_xwMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerSkx0eXMBB6HeDb7etMFB74q8hSl0fk0hKx5OhJROiI4Meta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordVOR8D6hUPDBADudkHEbiyyGjbjRG5bVo10KqXcLNsaYMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexEUJWD_NF9A2VljgtKlwTaNVt5JCGmz6i8SLCGpTRjKUMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordB1Hp_45ikkS92u5UwzCYfURX0uqAjzu285Y52a9sZr4q0Meta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordwI2eJ5p4O7kLGnvtJWLMimzn_mIhaDi1VL62p75XJF8Meta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_937SgBY5TXZRVj0KzsuiuibkSrxMKcQWxKM3s0fPQQaSgMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexN1AgT5AJa7iJrSyH7oebZfF6esxk3y9ikBaf5bIGLUYMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index0Ihw57FlY1NEuOrdzz5smrKPN9zmLMHJdHUCn_ld6vsMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: index1Xej1kaQxUXvMNohiFTf82EXPMldgWPLcLDz9n0eDPkMeta?.props ?? {"verificationNeeded":true},
    meta: index1Xej1kaQxUXvMNohiFTf82EXPMldgWPLcLDz9n0eDPkMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsKLycZV9A35GGJSrGU2eqgLCqs485rQSIM4HJdNf2iVUMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexf143BKTJNEdpKLhVuwMdhJehW_45704O0EReHrItQM2DMMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackXiMQzER0Wf6s9YpIbKhrhoaPuuRO6NPGtDVhUEhTRCcMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate3EwQXgWeGSi4sa9RTVA3jo_HjPuhvUDgBFPJgFb4eWoMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: details0TsErlx0_45oBK5zabrYc7CHbuRFxZtpXv5h8xVGiwgaoMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerP_2zSeOZXyhPhv043EKp6NZRX1JGFN7gHGljnJUBBqgMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsC4sID4HUb8QsZwfPU92zdgjjNrxVD8c7J31xkedYEbEMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93_beMCwuRiNYjYDtffWK0ViZLVmOsEJAEj_V4fCTdjj4Meta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionINPkONJs0kdZbExf5gDV1fPgmZxKDVjSYqPPVsJltYYMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93xfrNSDT9oxd3UZwsnXeSNAv7ZKpFuClilsSUtoff8DIMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93lXN9OvVmGh5ZXu66lq85IKw1XcHmfP_VXLj2aqyLUa0Meta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93u3YXekKH8ViBpiQObxkYlUvtWlNKhai4YbbXeNMxdaAMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailssIQxKn0ew7M3HvrhEvRCLYEPsnQh94fKEbISe8tTKkwMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93VWFF_45BpwLcLJRnRloKoGCEHyZR7DFTmq2rs49pEij3UMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_937degR4dorbZnwNFrFBuUiVP1DlAdpR4vDZwKngerE5sMeta || {},
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src819564419/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]